import { createTheme } from "@mui/material/styles";

const theme = createTheme({
    palette: {
        primary: {
            main: "#6A52C2",
        },
    },
});

export default theme;
